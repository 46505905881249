﻿.mapPin {
    position: relative;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 100%;
    color: red;
    font-weight: bold;
    content: '\02299'; // Unicode circled dot
    // content: '\01F4CD'; // Unicode Pin 
}
