.openWidgetSettings {
    position: absolute;
    top: 9px;
    right: 11px;
    color: #666;
    font-size: 18px;
    opacity: 0.6;
    transition: opacity .2s;
}

.removeWidgetBtn {
    position: absolute;
    top: 9px;
    right: 35px;
    color: #666;
    font-size: 18px;
    opacity: 0.6;
    transition: opacity .2s;
}

.openWidgetSettings:hover, .removeWidgetBtn:hover{
    cursor: pointer;
    opacity: 1;
    transition: opacity .22s;
}

.modal-dialog {
    margin: 20vh auto 0px auto;

    // Choose AreaColor (Gauge + ProgressBar)
    .areaBlocks {
        transition: left 0.2s ease-in, background-color 0.2s ease-in;
    }

    // Map
    #mapItemRows {
        & .form-control {
            font-size: $font-size-base;
        }
        &>.row {
            padding: 0.5rem 0;
            &:nth-child(even) {
                background-color: $gray-100;
            }
        }
    }
}