.beehiveContainer {
    padding-top: 1rem;
    margin: 0 25%;
}

.beehive {
    transition: all 0.5s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transform-origin: bottom right;
    position: relative;
    width: 2.5rem;
    height: auto;
    transform: rotateZ(0);
}