// Widget classes are generated (into snake_case) on the values of "ClientComponentName" in the "widgettypes" table

.react-grid-layout {
  & > :not(.widget_progress_bar) .inner-card {
    height: 100%;
    overflow: hidden;
  }
  & > .widget_gauge .inner-card {
    max-height: 88%;
  }
  /*
  & > .widget_google_maps {
  }
  & > .widget_icon_with_value .inner-card {
  }
  & > .widget_line_chart {
  }
  & > .widget_progress_bar {
  }
  & > .widget_sensor_table {
  }
  */
}
