/**********************************************************************************************
HOW TO ADD A NEW ICON: 
  1. Add the SVG file to this folder:
    /Xlent.IoT.API/ClientApp/src/assets/components/icons/icons-svg

  2. Add the icon to the 'icons' table in MySQL 
  
  3. Then add the name to this list: (no "pe-7s-" prefix needed)
*/
$svgs: accessibility, activity, bin, bolt, cancel, danger, exit, location, ok-circle, temperature;

/*
NOTE: Filename, name in database and list must match exactly.

**********************************************************************************************/


/******************
General settings 
******************/
$icon-dir: './components/icons/icons-svg/';

@mixin bgSettings {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}



/*************************
 Toolbox (left side menu)
*************************/

// Root folder icons
%toolbox-folder-svg-styles {
  display: block;
  content: ' ';
  width: 1.5rem;
  height: 100%;
  margin: auto;
  @include bgSettings;
  background-position-y: 5px;
  // Force a blue color to folder icons:
  -webkit-filter: grayscale(100%) brightness(60%) sepia(100%) hue-rotate(-180deg) saturate(1000%) contrast(0.8);
  filter: grayscale(100%) brightness(60%) sepia(100%) hue-rotate(-180deg) saturate(1000%) contrast(0.8);
}

.metismenu-link {
  @each $svg in $svgs {
    i.metismenu-icon.pe-7s-#{$svg} {
      opacity: 0.9;
      &::before {
        @extend %toolbox-folder-svg-styles;
        background-image: url($icon-dir+$svg+'.svg');
      }
    }
  }
}

// Sensor icons
%toolbox-item-svg-styles {
  display: block;
  content: ' ';
  width: 0.9rem;
  height: 1.1rem;
  position: relative;
  top: 4px;
  @include bgSettings;
}

.metismenu-link {
  @each $svg in $svgs {
    i:not(.metismenu-icon).pe-7s-#{$svg}::before {
      @extend %toolbox-item-svg-styles;
      background-image: url($icon-dir+$svg+'.svg');
    }
  }
}

/******************************
 Inside the icon-picker modal 
 *****************************/

%picker-svg-styles {
  height: 1.3rem;
  width: 1.3rem;
  opacity: 0.8;
  @include bgSettings;
}

@each $svg in $svgs {
  .rfip i.pe-7s- {
    &#{$svg} {
      @extend %picker-svg-styles;
      background-image: url($icon-dir+$svg+'.svg');
    }
  }
}

/****************************
 Sensor administration tree 
****************************/

%admin-svg-styles {
  width: auto;
  height: 26px;
  display: block;
  content: ' ';
  opacity: 0.7;
  @include bgSettings;
}

@each $svg in $svgs {
  .rst__row.pe-7s-#{$svg} .rst__moveHandle::before {
    @extend %admin-svg-styles;
    background-image: url($icon-dir+$svg+'.svg');
  }
}
/******************************
 Child widget icons modal (Table & Map widgets)
 *****************************/

 %child-svg-styles {
  height: 1.15rem;
  width: 1.15rem;
  opacity: 0.5;
  @include bgSettings;
}

@each $svg in $svgs {
  #listChildren table i.pe-7s- {
    &#{$svg} {
      @extend %child-svg-styles;
      background-image: url($icon-dir+$svg+'.svg');
    }
  }
}