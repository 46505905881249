.counter-inner-div {
    // display: flex;
    display: inline-block;
    background: #555;
    margin: auto;
    color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border:2px solid #999;
    box-shadow: 1px 1px 5px #ccc;
    // width: 100%;
}

.counter-value{
    font-size: calc(1em + 1vw);
    text-align: center;
    letter-spacing: .5em;
    text-indent: .5em;
}

.description {
    background: #fff;
    color:#333;
    width: 100%;
    text-align: center;
    padding: 0 10%;
    font-size: .8em;
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
}