.save-button-icon{
    margin-right: .5rem;
    margin-top: 2px;
    font-size: 26px;
    color: #666;
}

.save-dashboard-button-outer:hover{
    cursor: pointer;
}

.saveDashboardAni {
    animation: saveButtonAni .7s;
    -webkit-animation: saveButtonAni .7s;
}

@keyframes saveButtonAni {
    0% {
        color:#666;
    }
    60% {
        color: #48c986;
    }
    100%{
        color: #666;
    }
}

@-webkit-keyframes saveButtonAni {
    0% {
        color:#666;
    }
    60% {
        color: #48c986;
    }
    100%{
        color: #666;
    }
}
