
.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
	& .react-draggable .card-title:hover{
		cursor: move;
	}
}
.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
	>.react-resizable-handle {
		position: absolute;
		width: 20px;
		height: 20px;
		bottom: 0;
		right: 0;
		cursor: se-resize;
		&::after {
			content: "";
			position: absolute;
			right: 3px;
			bottom: 3px;
			width: 5px;
			height: 5px;
			border-right: 2px solid rgba(0, 0, 0, 0.4);
			border-bottom: 2px solid rgba(0, 0, 0, 0.4);
		}
	}
 	&.cssTransforms {
	transition-property: transform;
	}
	&.resizing {
		z-index: 1;
		will-change: width, height;
	}
	&.react-draggable-dragging {
		transition: none;
		z-index: 3;
		will-change: transform;
	}
	&.react-grid-placeholder {
		background: $brand;
		border-radius: 0.25rem;
		opacity: 0.2;
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
}