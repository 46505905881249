.progressBar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 10px;
   // padding-bottom: 48%;  // 96/48 to keep aspect ratio of box = 2:1
    margin: 0 auto;

    &Block {
        position: absolute;
        bottom: 0;
        transform-origin: bottom;
        width: 100%;
        height: 10px;
        
        &Mask {
            opacity: 0.8;
        }
    }

    &MaxMinLabels {
        font-size: 0.8em;
    }
}

.progressBarVertical {
    position: relative;
    overflow: hidden;
    width: 10px;
    height: 100%;
   // padding-bottom: 48%;  // 96/48 to keep aspect ratio of box = 2:1
    margin: 0 auto;

    &Block {
        position: absolute;
        bottom: 0;
        transform-origin: right;
        width: 10px;
        height: 100%;
        
        &Mask {
            opacity: 0.8;
        }
    }

    &MaxMinLabels {
        font-size: 0.8em;
    }
}