.react-resizable {
  position: relative;
  
  &-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  
    &-sw {
      bottom: 0;
      left: 0;
      cursor: sw-resize;
      transform: rotate(90deg);
    }
    &-se {
      bottom: 0;
      right: 0;
      cursor: se-resize;
    }
    &-nw {
      top: 0;
      left: 0;
      cursor: nw-resize;
      transform: rotate(180deg);
    }
    &-ne {
      top: 0;
      right: 0;
      cursor: ne-resize;
      transform: rotate(270deg);
    }
    &-w,
    &-e {
      top: 50%;
      margin-top: -10px;
      cursor: ew-resize;
    }
    &-w {
      left: 0;
      transform: rotate(135deg);
    }
    &-e {
      right: 0;
      transform: rotate(315deg);
    }
    &-n,
    &-s {
      left: 50%;
      margin-left: -10px;
      cursor: ns-resize;
    }
    &-n {
      top: 0;
      transform: rotate(225deg);
    }
    &-s {
      bottom: 0;
      transform: rotate(45deg);
    }
  }
}