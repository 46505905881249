.gaugeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50 / 100 * 100%; // Force aspect ratio to 2:1
  overflow: hidden;
}

.gauge {
  position: absolute;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  margin: 0 auto;

  &Face {
    position: absolute;
    bottom: 0;
    transform-origin: bottom;

    &Mask {
      transform: rotateZ(0deg);
    }
    &First {
      transform: rotateZ(0deg);
    }
    &Pointer {
      transform-origin: 50% 90%;
      padding: 0 5%;
      width: 100%;
    }
    &Area {
      border-top-left-radius: 50% 100%;
      border-top-right-radius: 50% 100%;
      width: 98%;
      height: 98%;
      left: 1%;
    }
  }

  &MaxMinLabels {
    width: 96%;
    margin: 1% 2%;
    font-size: 0.8em;
  }
}